import React, { Fragment } from 'react'
import { useKeycloak } from '@react-keycloak/web';
import Header from './Header'
import Footer from './Footer'
import SideBar from './SideBar'
import FNSpinner from '../../components/FNSpinner/FSpinner';

const Layout = ({ children }) => {
    const { initialized } = useKeycloak();
    return (
        <>
            {!initialized ? (
                <FNSpinner />
            ) : (
                <Fragment>
                    <div id="layout-wrapper">
                        <Header />
                        <SideBar />
                        <div class="main-content">
                            <div class="page-content">
                                <div class="container-fluid">
                                    {children}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </Fragment>
            )}
        </>
    )
}

export default Layout