import React, { Fragment } from 'react'
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from './helpers/keycloak';

import Layout from './components/Layout';
import PrivateRoute from './helpers/protected';

import Hfqap from './pages/Hfqap';
import Sqis from './pages/Sqis';
import Training from './pages/Training';
import Unmc from './pages/Councils/Unmc';
import Allied from './pages/Councils/Allied';
import Medical from './pages/Councils/Medical';
import RawData from './pages/Hfqap/RawData';
import StandardScores from './pages/Hfqap/StandardScores';
import Performance from './pages/Sqis/Performance';
import FacilityPerformance from './pages/Hfqap/FacilityPerformance';
import FacilityScores from './pages/Hfqap/FacilityScores';
import Details from './pages/Hfqap/StandardScores/Details';
import AssessedFacilities from './pages/Hfqap/AssessedFacilities';

const App = () => {

  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <ToastContainer />
      <Switch>
        <PrivateRoute path="/">
          <Layout>
            <Switch>
              <PrivateRoute exact path="/hfqap" component={Hfqap} />
              <PrivateRoute exact path="/hfqap/dataset" component={RawData} />
              <PrivateRoute exact path="/sqis" component={Sqis} />
              <PrivateRoute exact path="/sqis/performance" component={Performance} />
              <PrivateRoute exact path="/training" component={Training} />
              <PrivateRoute exact path="/council/unmc" component={Unmc} />
              <PrivateRoute exact path="/council/medical" component={Medical} />
              <PrivateRoute exact path="/council/allied" component={Allied} />
              <PrivateRoute exact path="/hfqap/standards/scores" component={StandardScores} />
              <PrivateRoute exact path="/hfqap/facility/scores" component={FacilityScores} />
              <PrivateRoute exact path="/hfqap/facilities/assessed" component={AssessedFacilities} />
              <PrivateRoute exact path="/hfqap/performance" component={FacilityPerformance} />
              <PrivateRoute exact path="/hfqap/performance/:facility" component={Details} />
            </Switch>
          </Layout>
        </PrivateRoute>
      </Switch>
    </ReactKeycloakProvider>
  )
}

export default App