import React from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
    return (
        <div class="vertical-menu">
            <div data-simplebar class="h-100">
                <div id="sidebar-menu">
                    <ul class="metismenu list-unstyled" id="side-menu">
                        <li class="menu-title" key="t-menu">Menu</li>
                        <li class="menu-title" key="t-apps">Support Supervision</li>
                        <li>
                            <Link to="/hfqap" class="waves-effect">
                                <i class="bx bx-chat"></i>
                                <span key="t-chat">HFQAP</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/hfqap/facility/scores" class="waves-effect">
                                <i class="bx bx-chat"></i>
                                <span key="t-chat">Modular Performance</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/hfqap/standards/scores" class="waves-effect">
                                <i class="bx bx-chat"></i>
                                <span key="t-chat">Standards Scorecard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/hfqap/facilities/assessed" class="waves-effect">
                                <i class="bx bx-chat"></i>
                                <span key="t-chat">Facilities Assessed</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/hfqap/performance" class="waves-effect">
                                <i class="bx bx-chat"></i>
                                <span key="t-chat">District Scorecard</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/sqis" class="waves-effect">
                                <i class="bx bx-file"></i>
                                <span key="t-file-manager">SQIS+</span>
                            </Link>
                            <li>
                                <Link to="/sqis/performance" class="waves-effect">
                                    <i class="bx bx-chat"></i>
                                    <span key="t-chat">Facilities Performance</span>
                                </Link>
                            </li>
                        </li>
                        <li class="menu-title" key="t-pages">Licensing & Registration</li>
                        <li>
                            <Link to="/council/medical" class="waves-effect">
                                <i class="bx bx-share-alt"></i>
                                <span key="t-multi-level">Medical Council</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/council/allied" class="waves-effect">
                                <i class="bx bx-calendar"></i>
                                <span key="t-multi-level">Allied Council</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/council/unmc" class="waves-effect">
                                <i class="bx bx-map"></i>
                                <span key="t-maps">Nurses Council</span>
                            </Link>
                        </li>
                        <li class="menu-title" key="t-pages">Capacity Building</li>
                        <li>
                            <Link to="/training" class="waves-effect">
                                <i class="bx bx-aperture"></i>
                                <span key="t-icons">Trainings & Mentorship</span>
                            </Link>
                            {/* <Link to="/training/database" class="waves-effect">
                                <i class="bx bxs-user-detail"></i>
                                <span key="t-icons">Training Database</span>
                            </Link> */}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideBar